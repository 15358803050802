import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/Nav.css';
import { toggleMobileNavMenu } from '../Nav/Nav';

const MobileNav = () => {
  return (
    <div
      id="mobile-nav"
      className="mobile-nav-container container-fluid slideout d-block"
    >
      <button
        id="nav-btn"
        className="burger-btn-close"
        onClick={toggleMobileNavMenu}
      >
        <i className="fa-solid fa-xmark"></i>
      </button>
      {/* Links */}
      <div
        className="row gx-0 text-center"
        style={{ fontWeight: 600, fontSize: '7rem' }}
      >
        <Link
          to="/"
          className="mobile-links col-12"
          onClick={toggleMobileNavMenu}
        >
          Home
        </Link>
        <Link
          to="/About"
          className="mobile-links col-12"
          onClick={toggleMobileNavMenu}
        >
          About
        </Link>
        <Link
          to="/Work"
          className="mobile-links col-12"
          onClick={toggleMobileNavMenu}
        >
          Work
        </Link>
        <Link
          to="/Contact"
          className="mobile-links col-12"
          onClick={toggleMobileNavMenu}
        >
          Contact
        </Link>
      </div>
    </div>
  );
};

export default MobileNav;
