import React from 'react';
import { ContactForm } from '../../components/index';
import '../../styles/Contact.css';

const Contact = () => {
  // Scrolls to top of page on load
  window.scroll(0, 0);

  return (
    <div className="row" style={{ padding: '1rem 0', margin: 'auto 0 0 0' }}>
      <h2 className="text-center contact-heading col-12">Let's Talk!</h2>
      <ContactForm />
    </div>
  );
};

export default Contact;
