import React from 'react';
import '../../styles/Footer.css';

const Footer = () => {
  return (
    <footer className="container-fluid">
      <div className="d-flex flex-row justify-content-around align-items-center text-center my-2">
        <div className="links btn">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/whalehellocreative/"
          >
            <i
              className="fa fa-facebook-official social-icons"
              aria-hidden="true"
            ></i>{' '}
            <span className="social-links">Find me on Facebook!</span>
          </a>
        </div>
        <div className="links btn">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/whalehellocreative/"
          >
            <i className="fa fa-instagram social-icons"></i>{' '}
            <span className="social-links">Find me on Instagram!</span>
          </a>
        </div>
        <div className="links btn">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/channel/UCAh21IB1ikWqopsUwXAa6Ww/videos"
          >
            <i className="fa fa-youtube social-icons"></i>{' '}
            <span className="social-links">Find me on Youtube!</span>
          </a>
        </div>
      </div>
      <div className="d-flex flex-row justify-content-around lign-items-center text-center my-2">
        <a href="/privacy-policy">
          <span className="btn-sm links text-small">Privacy Policy</span>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
