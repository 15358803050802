export const projects = [
  {
    key: 1,
    title: 'Sea Watch Foundation',
    description:
      'The waters around the UK are teeming with life and Sea Watch Foundation wanted to inspire their followers and encourage them to take an interest in the whales and dolphins living off our coasts. Having volunteered with them for a number of years writing articles for their dolphin adoption newsletter, I was asked to create a series of videos highlighting some of the many sightings. Based on a script and a voice over sent to me,  I created monthly animated maps with graphic to illustrate where and which species had been spotted.',
    videoId: '6KbgjW3kiyE',
  },
  {
    key: 2,
    title: 'Cardiff Canine Citizens',
    description:
      'Lots of people own pets and lots of people own dogs, myself included.  Understanding and training your dog is an essential part of ownership but it can be hard to know where to start. I filmed and edited a series of videos for Caroline Cowan of Cardiff Canine Citizens, a canine behaviourist and trainer based in Cardiff promoting her classes online on various social media platforms to help people find their way.  I also created a number of “highlights” videos of the South Wales Dog and Family Fun Day, a not-for-profit event run by Caroline to raise money for various dog charities.',
    videoId: 'TAG6Iz3TYmw',
  },
  {
    key: 3,
    title: 'MiCreation: Mi Little Pad & Kids With Bricks',
    description:
      'The Vikings…space….the rainforests of Borneo- just some of the topics I’ve delved into when editing a series of videos for the Mi Little Pad and Kids With Bricks educational after school clubs. The videos were created to deliver fun facts in an interesting way, to encourage children to want to learn more about the topic of the week and engage in building that week’s challenge. My role often involved researching the subject matter, writing the scheme of work and the scripts prior to filming and then meeting with and filming the presenter of the videos before editing the material together.',
    videoId: 'viQ6MVcXyIM',
  },
  {
    key: 4,
    title: 'MiCreation: Artikids',
    description:
      'Art is a brilliant creative outlet for children and I’ve been creating videos for a new arty after school club- ‘Artikids’. These clubs are arts and crafts based and so I tend to centre the videos around this theme. I’ve created a world of fuzzy felt animals, a prehistoric party collage and explored a paper craft space intended to spark the children’s imaginations. I am provided with the topic of the term and then I write the scheme of work and the script which I send to the presenter to film and then edit the footage together with my animations and a craft build to create fun videos designed to inspire young minds.',
    videoId: 'okU7vMkHpNs',
  },
  {
    key: 5,
    title: 'Kenworthy Chiropractic',
    description:
      'Whether you’re a runner looking to improve your performance or simply want to improve your posture and general health, chiropractic care can be very useful (especially for video editors who use desks all day!) However, finding the right clinic for you and visiting it for the first time can be a little overwhelming. I filmed and edited a video for potential patients of ‘Kenworthy Chiropractic’ to explain the process of an initial consultation to help make it feel less intimidating. The idea was to keep it fun and upbeat and to reflect the values of the business which we achieved by keeping the script short and adding in hand drawn graphics. <br>I also created a series of short videos showing patients how to carry out recommended exercises correctly and safely and a number of animated social media videos.',
    videoId: 'o-COalZuGvI',
  },
];