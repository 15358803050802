export const projects = [
  {
    key: 1,
    title: 'Fuzzy Felt Animals',
    description:
      'Having created a number of felt animals for another project I ‘felt’ inspired to create this quick animation. I love the stop motion style and I’m particularly proud of my lion fish- it took a long time but it was worth it.',
    videoId: 'CSAa4_8bF3k',
  },
  {
    key: 2,
    title: 'Christmas Animations',
    description:
      'I really enjoy exploring different animation styles and so the countdown to Christmas was a great way for me to experiment. Some of them were hand drawn and others made within the editing program, some took hours and some took days, some were a lot harder than others but it was a fun challenge! ',
    videoId: 's7dAYhCTJwc',
  },
  {
    key: 3,
    title: 'Character Animations',
    description:
      'Who doesn’t love a happy little conker? These little characters are really fun to create. I particularly like the fact that they don’t have to be overcomplicated to be interesting- often the simplest movements with simple shapes can be the most effective. ',
    videoId: '6_2KEtt7lnk',
  },
  {
    key: 4,
    title: 'Out of Office',
    description:
      'What better way is there to express myself than with an animation? I created this short loop for my ‘Out of Office’ email signature to let people know I was away for a little while! ',
    videoId: 'oEWVGcwDp_0',
  },
  {
    key: 5,
    title: 'Travel Videos',
    description:
      'I love being able to travel and explore the world and I’ve been lucky enough to visit some amazing places. By filming little bits here and there and putting it together, I now have a visual record of some of my adventures that I can look back on for years to come. ',
    videoId: '8r5ub-pjFV8',
  },
];