import React from 'react';
import lottie from 'lottie-web/build/player/lottie_light';

import '../../styles/AnimatedImage.css';

export default function AnimatedImage({
  loop,
  autoplay,
  animationData,
  container,
}) {
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.getElementById(`lottie-${container}`),
      animationData: animationData,
      renderer: 'svg',
      loop: loop ? true : false,
      autoplay: autoplay ? true : false,
    });
  });

  return <div className={`lottie-${container}`} id={`lottie-${container}`} />;
}
