import { Categories, ProjectHead } from '../../components/index';

import { Fade } from "react-awesome-reveal";
import React from 'react';
import { categories } from '../../static/Categories.js';

const Work = () => {
  // Scrolls to top of page on load
  window.scroll(0, 0);

  return (
    <main className="container mb-100">
      <Fade>
        <ProjectHead videoId="hW9tv3hFsH8" />
      </Fade>

      {categories.map(({ key, title, description, image, animation }) => (
        <Fade key={key} delay={300}>
          <Categories
            id={key}
            route={title.replace(/\s+/g, '-').toLowerCase()}
            title={title}
            description={description}
            image={image}
            locLeft={key % 2 ? true : false}
            bgColor={key % 2 ? 'pink' : 'blue'}
            jsonAnimation={animation}
          />
        </Fade>
      ))}
    </main>
  );
};

export default Work;
