import React from 'react';
import { EmbedYoutube } from '../index';

import '../../styles/ProjectHead.css';

const ProjectHead = ({ videoId }) => {
  return (
    <div className="row gx-0">
      <div className="col-12 project-head-container">
        <div className="row gx-0">
          <div className="project-head-container--inner mx-auto col-12 col-md-8">
            <EmbedYoutube embedId={videoId} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectHead;
