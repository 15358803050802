import React from "react";
import PropTypes from "prop-types";

// Creates and iframe using the embedId

const EmbedYoutube = ({ embedId }) => (
  <iframe
    width="100%"
    height="100%"
    src={`https://www.youtube.com/embed/${embedId}`}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
    title="Embedded youtube"
  />
);

EmbedYoutube.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default EmbedYoutube;
