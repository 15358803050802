import '../../styles/ContactForm.css';

import React from 'react';
import emailjs from 'emailjs-com';

export default function ContactForm() {
  function sendEmail(e) {
    const statusContainer = document.getElementById('message-status');
    const submitButton = document.getElementById('contact-submit');
    const cName = document.getElementById('contact-name');
    const email = document.getElementById('contact-email');
    const message = document.getElementById('contact-message');

    submitButton.disabled = true;
    submitButton.value = 'Sending...';
    e.preventDefault();

    statusContainer.innerHTML = '';

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE,
        process.env.REACT_APP_EMAILJS_TEMPLATE,
        e.target,
        process.env.REACT_APP_EMAILJS_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          submitButton.value = 'Send';
          const text = 'Message Sent - Thank You!';
          const statusMessage = document.createElement('div');
          statusMessage.classList.add('green');
          statusMessage.innerText = text;
          statusContainer.appendChild(statusMessage);
          setTimeout(function () {
            submitButton.disabled = false;
            cName.value = '';
            email.value = '';
            message.value = '';
          }, 2000);
        },
        (error) => {
          console.log(error.text);
          submitButton.value = 'Retry?';
          const text = 'Failed to send message :-(';
          const statusMessage = document.createElement('div');
          statusMessage.classList.add('red');
          statusMessage.innerText = text;
          statusContainer.appendChild(statusMessage);
          setTimeout(function () {
            submitButton.disabled = false;
            cName.value = '';
            email.value = '';
            message.value = '';
          }, 2000);
        }
      );
  }

  return (
    <div className="contact-container pink-bg col-12 col-md-8 col-lg-6 col-xl-4">
      <form className="contact-form" onSubmit={sendEmail}>
        <input
          type="text"
          name="from_name"
          className="contact-input"
          placeholder="Name"
          id="contact-name"
          required
        />
        <input
          type="email"
          name="from_email"
          className="contact-input"
          placeholder="Email Address"
          id="contact-email"
          required
        />
        <textarea
          name="message"
          className="contact-input contact-message"
          placeholder="Enter your message here"
          id="contact-message"
          required
        />
        <div className="text-center">
          <input
            type="submit"
            value="Send"
            className="text-center btn-blue btn-contact"
            id="contact-submit"
          />
        </div>
      </form>
      <span className="text-center" id="message-status"></span>
    </div>
  );
}
