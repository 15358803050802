import './styles/App.css';

import {
  About,
  ClientWork,
  Contact,
  Home,
  PassionProjects,
  PrivacyPolicy,
  Work,
} from './Routes/index';
import { Footer, Nav } from './components/index';
import { Route, Routes } from 'react-router-dom';

import React from 'react';

const App = () => {
  return (
    <>
      <Nav />
      <div className="full-height container-fluid g-0 my-auto">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" component={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="/work">
            <Route index element={<Work />} />
            <Route path="client-work" element={<ClientWork />} />
            <Route path="passion-projects" element={<PassionProjects />} />
          </Route>
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
};

export default App;
