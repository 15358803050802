import PassionProjects from '../assets/media/images/passion-projects-image.webp';
import ClientWork from '../assets/media/images/client-work-image.webp';

export const categories = [
  {
    key: 1,
    title: 'client work',
    description:
      'The variety of the projects I get to work on is one of my favourite parts of being a video editor and I love exploring different styles with different subjects and different aims. I’ve included some of my most recent work within this section to showcase a range of projects.',
    image: ClientWork,
  },
  {
    key: 2,
    title: 'passion projects',
    description:
      'When I’m not editing or trying in vain to learn the keyboard (thought I’m pretty good at ‘This Land’ from The Lion King now…), I can often be found with my laptop animating turtles or Christmas countdowns. Here are a couple of examples of videos I put together in my spare time, often accompanied by impressive selection of snacks.',
    image: PassionProjects,
  },
];