import React from 'react';
import { AnimatedImage } from '../../components/index';

import '../../styles/About.css';
import helloAnimation from '../../assets/media/lotties/hello.json';
import factsAnimation from '../../assets/media/lotties/quick-facts.json';
import AboutImage from '../../assets/media/images/about-me-image.webp';

const About = () => {
  // Scrolls to top of page on load
  window.scroll(0, 0);

  return (
    <main className="container full-height my-auto ">
      <div className="row gx-0 about-container blue-bg">
        <div className="col-12 col-md-8">
          <div className="row d-flex justify-content-center overflow-hidden">
            <div className="col-12 about-hello-image-container">
              <AnimatedImage
                autoplay
                animationData={helloAnimation}
                container="hello"
              />
            </div>

            <div className="col-10 mx-auto about-text-container">
              <p>
                I’m Arianne. I love space, the ocean, cheese and I’m a video
                editor.
              </p>
              <p>
                I’ve been working as a video editor for over 10 years now after
                graduating from the University of Glamorgan with a degree in
                Television. It was during my course that I discovered my love
                for editing videos; telling stories in a way that makes people
                sit back and think “I like that” or “I want to be a part of
                that”. Prior to this my editing journey had begun with me making
                a couple of particularly cheesy Torchwood videos cut to music.
                I’ve definitely come a long way since then!
              </p>
              <p>
                I enjoy the variety of work that comes with being a freelance
                editor. Some weeks I might be working on videos promoting
                businesses and other times creating educational videos for after
                school clubs. I really love when I’m able to combine my
                interests with my work and get to create planets or design
                underwater themes with whales and dolphins.
              </p>

              <div className="row">
                <div className="col-12 offset-lg-4 col-lg-8">
                  <p>
                    It was a natural progression for me to move more into the
                    realm of motion design. I’d been adding little pieces
                    throughout my videos for a couple of years before I realised
                    just how much I looked forward to working on these sections.
                    I’ve always been a creative person, always taking the
                    opportunity to design and decorate- even my to do lists
                    don’t escape. Being able turn my little ideas into real
                    animations, to learn and explain different subjects through
                    different styles is a lot of fun and it’s definitely one of
                    my favourite parts of my job (another is being able to eat
                    as many biscuits as I want).
                  </p>
                </div>
                <p>
                  I’m always open to hearing about new projects so don’t
                  hesitate to reach out to me. Let’s see what we can create
                  together.
                </p>
              </div>
            </div>
            <AnimatedImage
              autoplay
              loop
              animationData={factsAnimation}
              container="facts"
            />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="about-image-container pink-bg">
            <img src={AboutImage} alt="Me" />
          </div>
        </div>
      </div>
    </main>
  );
};

export default About;
