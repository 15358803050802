import '../../styles/Project.css';
import '../../styles/Categories.css';

import { EmbedYoutube } from '../index';
import { Fade } from "react-awesome-reveal";
import React from 'react';

// A project container displayed to the left or right with a image, text and button
// title - title of the category
// description - text for the category box
// image - if present image to be displayed
// jsonAnimation - if present animated image to be displayed
// bgColor - color of the background assigned as a class e.g. 'blue' or 'pink' '-bg' is added already. button is assigned the alternative
// locLeft - if present, container is moved to left on screen, right otherwise
// video - if present renders youtube video frame and adds teh video id provided

const project = ({ title, description, image, bgColor, locLeft, videoId }) => {
  const TextFormatter = ({ text, classes }) => {
    return (
      <p className={`${classes}`} dangerouslySetInnerHTML={{ __html: text }} />
    );
  };
  return (
    <div className="row gx-0">
      <div
        className={
          locLeft
            ? 'col-sm-12 col-md-9 project-container ' + bgColor + '-bg'
            : 'offset-md-3 col-sm-12 col-md-9 project-container ' +
            bgColor +
            '-bg'
        }
      >
        <div className="row gx-0">
          <Fade>
            <div
              className={`col-12 col-md-7 ${locLeft ? 'order-1 no-order' : 'order-2 no-order'
                }`}
            >
              <div className="row gx-0">
                {/* Title */}
                <h2
                  className={
                    locLeft ? 'col-12 project-title' : 'col-12 project-title'
                  }
                >
                  {title}
                </h2>
                {/* Description */}
                <TextFormatter
                  text={description}
                  classes={
                    locLeft
                      ? 'col-12 project-description'
                      : 'col-12 project-description'
                  }
                />
              </div>
            </div>
          </Fade>
          <Fade delay={300}>
            <div
              className={`col-12 col-sm-8 mx-auto col-md-5 media-container ${locLeft ? 'order-2 no-order' : 'order-1 no-order'
                }`}
            >
              <div className="row gx-0">
                {/* If an image is present */}
                {image ? (
                  <div className="col-12 media-container--inner">
                    <img
                      className={
                        locLeft ? 'right project-image' : 'left project-image'
                      }
                      src={image}
                      alt={title + ' image'}
                    />
                  </div>
                ) : (
                  ''
                )}
                {/* If a video is present */}
                {videoId ? (
                  <div className="col-12 media-container--inner">
                    <EmbedYoutube embedId={videoId} />{' '}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default project;
