import React from 'react';

const PrivacyPolicy = () => {
  // Scrolls to top of page on load
  window.scroll(0, 0);

  return (
    <div className="row">
      <div className="col-12 col-md-6 my-5 mx-auto pink-bg p-4 rounded">
        <h2 className="h1 underline text-center display-3">Privacy Policy</h2>
        <ul className="d-flex flex-column mx-autofont-weight-normal">
          <li>
            Whale Hello is committed to safeguarding the privacy of our clients
            in full compliance of the Data Protection Act 2018 (this includes
            the General Data Protection Regulations (GDPR):
          </li>
          <br />
          <li>
            <ul className=" d-flex flex-column list-disc">
              <li>
                The client’s request for the editor to undertake work/projects
                and Whale Hello agreeing to do so constitutes a “Contract”.
                Whale Hello needs to collect information about your requirements
                in order to ensure that the contract can be fulfilled.{' '}
              </li>
              <li>
                Whale Hello has a “Legitimate Interest” in collecting that
                information because without it we could not perform our duties
                under the Contract effectively.
              </li>
              <li>
                Whale Hello also has a “Legitimate Interest” in being able to
                contact you via email and telephone to update you on the
                work/projects and to update you on any relevant business
                information that may affect you.{' '}
              </li>
            </ul>
          </li>
          <br />
          <li>
            We only hold such data (name, email, phone number and address)
            necessary for us to contact you.{' '}
          </li>
          <br />
          <li>
            <span className="text-decoration-underline">Cookie Policy</span>
          </li>
          <li>
            Whale Hello does not use any 3rd party cookies for marketing
            purposes.
          </li>
          <br />
          <li>
            <span className="text-decoration-underline">Other Disclousers</span>
          </li>
          <li>
            <ul className=" d-flex flex-column px-5">
              <li>1. We may disclose information about you:</li>
              <li>
                <ul className=" d-flex flex-column list-disc">
                  <li>to the extent that we are required to do so by law;</li>
                  <li>
                    in connection with any legal proceedings or prospective
                    legal proceedings;
                  </li>
                  <li>
                    in order to establish, exercise or defend our legal rights
                    (including providing information to others for the purposes
                    of fraud prevention and reducing credit risk); and Except as
                    provided in this privacy policy, we will not provide your
                    information to third parties.
                  </li>
                </ul>
              </li>
              <li>2. Your rights:</li>
              <li>
                <ul className=" d-flex flex-column list-disc">
                  <li>
                    Under the Data Protection Act 2018 (including the General
                    Data Protection Regulations (GDPR) you may request details
                    of personal information that we hold about you.
                  </li>
                </ul>
              </li>
              <br />
              <li>
                If you have any questions about this privacy policy or our
                treatment of your personal data, please write to us by email
                to whalehellocreative@gmail.com
              </li>
              <br />
              <li>
                Access from whalehello.co.uk is fully protected (SSL certificate
                with Hostinger)
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
