import React from 'react';
import '../../styles/Home.css';
import { FullWidthBanner } from '../../components/index';
import bannerImage1 from '../../assets/media/images/banner1.webp';
import bannerImage2 from '../../assets/media/images/banner2.webp';
import bannerImage3 from '../../assets/media/images/banner3.webp';

const Home = () => {
  // Scrolls to top of page on load
  window.scroll(0, 0);

  const banners = [
    {
      key: 1,
      topImage: bannerImage1,
      text: 'I’m Arianne. I love space, the ocean, cheese and I’m a video editor.<br>I’ve worked as a freelance video editor for over 10 years working with individuals and businesses to create compelling and engaging video content to capture their personalities and share their vision.</br>Do you have a fully fledged concept or a fleeting idea and want to work with someone who  is often fuelled by cheddar?<br>Then get in touch and let’s see what we can create together. ',
      btnText: 'More About Me!',
      btnLink: '/about',
      imgPositionX: 'left',
      imgPositionY: 'top',
    },
    {
      key: 2,
      topImage: bannerImage2,
      text: 'I’m always open to hearing about new projects so don’t hesitate to reach out to me. Let’s see what we can create together.',
      imgParallax: true,
      imgPositionX: 'right',
      imgPositionY: 'center',
      btnText: 'Book a 1-2-1 session!',
      btnLink: '/contact',
    },
    {
      key: 3,
      topImage: bannerImage3,
      imgParallax: true,
      imgPositionY: 'center',
    },
  ];

  return (
    <>
      {banners.map(
        ({
          key,
          topImage,
          text,
          btnText,
          btnLink,
          imgParallax,
          imgPositionY,
          imgPositionX,
        }) => (
          <FullWidthBanner
            key={key}
            i={key}
            topImage={topImage}
            bgColor={key % 2 ? 'pink' : 'blue'}
            text={text}
            btnText={btnText}
            btnLink={btnLink}
            imgParallax={imgParallax}
            imgPositionY={imgPositionY}
            imgPositionX={imgPositionX}
            locLeft={key % 2 ? true : false}
          />
        )
      )}
    </>
  );
};

export default Home;
