import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/App.css';
import '../../styles/FullWidthBanner.css';

// A full page width banner with image at the top and coloured text box below
// Takes props bgColor - set the background color. Set at bg-*, set in css.
// text - text on banner.
// btnText - text for the button. (also indicates to display the button)
// btnLink - link for the button to use.
// topImage - image for the banner.
// imgParallax - sets if parallax effect works
// imgPositionX - the x axis position of the background image (center if not entered)
// imgPositionY - the y axis position of the background image (center if not entered)

const FullWidthBanner = ({
  i,
  bgColor,
  text,
  btnText,
  btnLink,
  topImage,
  imgParallax,
  imgPositionX,
  imgPositionY,
  locLeft,
}) => {
  // Formats text including html
  const TextFormatter = ({ text }) => {
    return <p dangerouslySetInnerHTML={{ __html: text }} />;
  };

  return (
    <div className="row gx-0">
      <div
        className={`banner-${i}-img section-img col-12 ${
          imgParallax ? 'banner-parallax' : 'banner'
        } position-x-${imgPositionX ? imgPositionX : 'center'} position-y-${
          imgPositionY ? imgPositionY : 'center'
        }`}
        style={topImage ? { backgroundImage: `url(${topImage}` } : ''}
      ></div>

      <section className={`px-2 section-container ${bgColor}-bg row gx-0`}>
        <div
          className={`col-12 ${btnText ? 'col-md-8' : null} ${
            locLeft ? 'order-1 no-order' : 'order-2 no-order'
          }`}
        >
          {text ? (
            <div className="banner-container">
              <TextFormatter text={text} />
            </div>
          ) : null}
        </div>
        {btnText ? (
          <div
            className={
              locLeft
                ? 'col-12 col-md-4 banner-btn mt-4 mt-md-auto order-2 no-order'
                : 'col-12 col-md-4 banner-btn mt-4 mt-md-auto order-1 no-order'
            }
          >
            <Link
              to={btnLink}
              className={
                bgColor === 'pink'
                  ? 'btn-blue btn btn-round'
                  : 'btn-pink btn btn-round'
              }
            >
              {btnText}
            </Link>
          </div>
        ) : (
          ''
        )}
      </section>
    </div>
  );
};

export default FullWidthBanner;
