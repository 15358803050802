import '../../styles/Nav.css';

import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { MobileNav } from '../index';
import logo from '../../assets/media/images/logo.webp';

const Nav = () => {
  const [isMobile, setIsMobile] = useState(getWindowSize());

  useEffect(() => {
    // Check window size on resizing
    function handleWindowResize() {
      setIsMobile(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <>
      {isMobile ? <MobileNav /> : ''}
      <nav className="container-fluid">
        <div className="logo-container">
          <Link to="/">
            <img
              height="150"
              width="400"
              src={logo}
              alt="Whale Hello Logo"
              className="logo"
            />
          </Link>
        </div>
        <div id="nav-menu-container" className="navbar row">
          {!isMobile ? (
            <>
              <div className="offset-xl-3 col-12 col-xl-6">
                <ul className="row gx-0  d-md-flex">
                  <li className="col-4 ">
                    <Link to="/about">About</Link>{' '}
                  </li>
                  <li className="col-4">
                    <Link to="/work">Work</Link>{' '}
                  </li>
                  <li className="col-4">
                    <Link to="/contact">Contact</Link>{' '}
                  </li>
                </ul>
              </div>
            </>
          ) : (
            <>
              {/* Mobile Nav */}
              <div id="mobile-nav-container" className="row text-start">
                {/* Burger Button */}
                <div className="col-12">
                  <button
                    id="nav-btn"
                    className="burger-btn"
                    onClick={toggleMobileNavMenu}
                  >
                    <i className="fa-solid fa-bars"></i>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </nav>
    </>
  );
};

export default Nav;

export function toggleMobileNavMenu() {
  // Manages the click of burger menu for nav
  const navCont = document.getElementById('mobile-nav');
  navCont.classList.toggle('slideout');
  navCont.classList.toggle('slidein');
  document.body.scrollTop = 0; // Safari
  document.documentElement.scrollTop = 0; // Chrome
}

function getWindowSize() {
  // gets the window size when called and returns true or false
  const width = window.innerWidth;
  if (width < '720') {
    return true;
  } else {
    return false;
  }
}
