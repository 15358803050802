import React from 'react';
import { Link } from 'react-router-dom';
import { AnimatedImage } from '../index';
import '../../styles/Categories.css';

// A category container displayed to the left or right with a image, text and button
// route - route the button will lead to e.g. /about
// title - title of the category
// description - text for the category box
// image - id present image to be displayed otherwise not rendered
// jsonAnimation - if present animation displayed otherwies not rendered
// bgColor - color of the background assigned as a class e.g. 'blue' or 'pink' '-bg' is added already. button is assigned the alternative
// locLeft - if present, container is moved to left on screen, right otherwise

const categories = ({
  id,
  route,
  title,
  description,
  image,
  bgColor,
  locLeft,
  jsonAnimation,
}) => {
  return (
    <div className="row gx-0">
      <div
        className={
          locLeft
            ? 'col-sm-12 col-md-9 category-container ' + bgColor + '-bg'
            : 'offset-md-3 col-sm-12 col-md-9 category-container ' +
              bgColor +
              '-bg'
        }
      >
        {/* Image */}
        <div>
          {image ? (
            <img
              className={
                locLeft ? 'right category-image' : 'left category-image'
              }
              src={image}
              alt={title + ' image'}
            />
          ) : (
            <div
              className={
                locLeft ? 'right category-image' : 'left category-image'
              }
            >
              <AnimatedImage
                loop
                autoplay
                animationData={jsonAnimation}
                container={`category-${id}`}
              />
            </div>
          )}
        </div>

        {/* Title */}
        <div className="row gx-0">
          <h2
            className={
              locLeft
                ? 'col-12 col-md-10 col-lg-8 category-title'
                : 'offset-md-2 offset-lg-4 col-12 col-md-10 col-lg-8 category-title'
            }
          >
            {title}
          </h2>
        </div>

        {/* Description */}

        <p
          className={
            locLeft
              ? 'col-md-10 col-lg-8 category-description'
              : 'offset-md-2 offset-lg-4 col-md-10 col-lg-8 category-description'
          }
        >
          {description}
        </p>

        {/* Button */}

        <div
          className={
            locLeft
              ? 'col-12 col-md-10 col-lg-8 mt-3 text-center category-btn'
              : 'offset-md-2 offset-lg-4 col-12 col-md-10 col-lg-8 mt-3 text-center category-btn'
          }
        >
          <Link
            to={route}
            className={
              bgColor === 'pink'
                ? 'btn-blue btn btn-category'
                : 'btn-pink btn btn-category'
            }
          >
            View Examples
          </Link>
        </div>
      </div>
    </div>
  );
};

export default categories;
