import ExampleMotion from '../../assets/media/lotties/examplemotion.json';
import { Fade } from "react-awesome-reveal";
import { Project } from '../../components/index';
import React from 'react';
import { projects } from '../../static/ClientWork';

const ClientWork = () => {
  // Scrolls to top of page on load
  window.scroll(0, 0);

  return (
    <main className="container mb-100">
      <Fade>
        <div className="row gx-0 p-3">
          <div className="col-12">
            <h1 className="text-center display-1 text-capitalize">
              Client Work
            </h1>
          </div>
        </div>
      </Fade>

      {projects.map(({ key, title, route, description, image, videoId }) => (
        <Fade key={key} delay={key * 250}>
          <Project
            id={key}
            route={title.replace(/\s+/g, '-').toLowerCase()}
            title={title}
            description={description}
            image={image}
            jsonAnimation={ExampleMotion}
            locLeft={key % 2 ? true : false}
            bgColor={key % 2 ? 'pink' : 'blue'}
            videoId={videoId}
          />
        </Fade>
      ))}
    </main>
  );
};

export default ClientWork;
